import React from 'react';
import { Page, View, Document, StyleSheet, Image, Text } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#FFFFFF',
        justifyContent: 'center',
        alignContent: 'center',
        padding: 10,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
        alignContent: 'center',
    },
    imageContainer: {
        width: '63mm',
        height: '88mm',
        position: 'relative',
        margin: 5,
    },
    image: {
        width: '100%',
        height: '100%',
    },
    textOverlay: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        color: 'white',
        fontSize: 32,
        textAlign: 'center',
    },
});

// Custom component for Image with text overlay
const ImageWithOverlay = ({ src, text }) => (
    <View style={styles.imageContainer}>
        <Image src={src} style={styles.image} />
        <Text style={styles.textOverlay}>{text}</Text>
    </View>
);

const PDFDocument = ({ selectedCards, selectedCardType, cardData }) => {
    // Create a new variable to hold modified selectedCards
    let modifiedSelectedCards = [...selectedCards];
    console.log(selectedCardType);

    // Check if selectedCardType is "fusionworld"
    if (selectedCardType === "fusionworld") {
        // Iterate over selectedCards to find cards ending with "-F"
        selectedCards.forEach((card) => {
            if (card['Key'].endsWith("-F")) {
                // Find the corresponding "-B" card in fusionworld.json
                const correspondingCard = cardData.find((fusionCard) => fusionCard['Key'] === card['Key'].replace("-F", "-B"));
                // If corresponding card is found, add it to modifiedSelectedCards
                if (correspondingCard) {
                    modifiedSelectedCards.push({ ...correspondingCard, count: 1 }); // Assuming count is 1 for the added card
                }
            }
        });
    }

    return (
        <Document>
            <Page size="Letter" style={styles.page}>
                <View style={styles.row}>
                    {modifiedSelectedCards.map((card, index) => {
                        const cardImage = `${card['Key']}`; // Construct the card image ID
                        const { count } = card; // Get the count of the card
                        // Render the card images with text overlay
                        console.log(cardImage);
                        return (
                            Array.from({ length: count }).map((_, idx) => (
                                <ImageWithOverlay key={`${cardImage}-${idx}`} src={`card_images/optcg/ST01-003.webp`} text="PROXY" />
                            ))
                        );
                    })}
                </View>
            </Page>
        </Document>
    );
};

export default PDFDocument;
