// NavBar.js
import React, { useState } from 'react';
import './NavBar.css'; // Import your styles if needed
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const NavBar = ({ onCardTypeChange, selectedCardType }) => {
    const [gameAnchorEl, setGameAnchorEl] = useState(null);
    const [decklistsAnchorEl, setDecklistsAnchorEl] = useState(null);

    const handleCardTypeChange = (cardType) => {
        onCardTypeChange(cardType);
        handleGameClose();
    };

    const handleGameClick = (event) => {
        setGameAnchorEl(event.currentTarget);
    };

    const handleDecklistsClick = (event) => {
        setDecklistsAnchorEl(event.currentTarget);
    };

    const handleGameClose = () => {
        setGameAnchorEl(null);
    };

    const handleDecklistsClose = () => {
        setDecklistsAnchorEl(null);
    };


    return (
        <div className="nav-bar">
            <div className="container NavContainer">
                <div className="LeftNavContainer">
                    <div className="logoContainer">
                        <img
                            src='assets/EgmanEventsLogoEAEA.png'
                            className='logo'
                            alt="egmanlogo"
                            onClick={() => window.location.href = "https://deckbuilder.egmanevents.com/"}
                        />
                    </div>
                    <div>
                        <Button
                            id="NavButton"
                            onClick={handleGameClick} // Open the menu on click
                        >
                            Build
                        </Button>
                        <Menu
                            anchorEl={gameAnchorEl}
                            open={Boolean(gameAnchorEl)}
                            onClose={handleGameClose} // Close the menu when clicked outside or Escape key is pressed
                        >
                            <MenuItem onClick={() => { handleCardTypeChange('optcg') }}>One Piece</MenuItem>
                            <MenuItem onClick={() => { handleCardTypeChange('fusionworld') }}>Fusion World</MenuItem>
                            <MenuItem onClick={() => { handleCardTypeChange('digimon') }}>Digimon</MenuItem>
                            <MenuItem onClick={() => { handleCardTypeChange('unionarena') }}>Union Arena</MenuItem>
                        </Menu>
                    </div>
                    <div>
                        <Button
                            id="NavButton"
                            onClick={handleDecklistsClick} // Open the menu on click
                        >
                            Tournaments
                        </Button>
                        <Menu
                            anchorEl={decklistsAnchorEl}
                            open={Boolean(decklistsAnchorEl)}
                            onClose={handleDecklistsClose} // Close the menu when clicked outside or Escape key is pressed
                        >
                            <MenuItem onClick={() => {
                                window.open('https://www.egmanevents.com/one-piece', '_blank');
                                handleDecklistsClose();
                            }}>One Piece</MenuItem>
                            <MenuItem onClick={() => {
                                window.open('https://www.egmanevents.com/dbs-fusion-world', '_blank');
                                handleDecklistsClose();
                            }}>Fusion World</MenuItem>
                            <MenuItem onClick={() => {
                                window.open('https://www.egmanevents.com/digimon', '_blank');
                                handleDecklistsClose();
                            }}>Digimon</MenuItem>
                        </Menu>
                    </div>
                    <div>
                        {selectedCardType.toLowerCase() === "optcg" && (
                            <Button id="NavButton"
                                onClick={() => {
                                    const baseUrl = "https://egmanevents.com/one-piece-op07/op07-deck-lists";
                                    window.open(baseUrl, '_blank');
                                }}>
                                OP07
                            </Button>
                        )}
                        {selectedCardType.toLowerCase() === "fusionworld" && (
                            <Button id="NavButton"
                                onClick={() => {
                                    const baseUrl = "https://egmanevents.com/fb03-team-decks";
                                    window.open(baseUrl, '_blank');
                                }}>
                                FB03
                            </Button>
                        )}
                        {selectedCardType.toLowerCase() === "digimon" && (
                            <Button id="NavButton"
                                onClick={() => {
                                    const baseUrl = "https://egmanevents.com/digi-bt16-format/egman-deck-lists";
                                    window.open(baseUrl, '_blank');
                                }}>
                                BT16
                            </Button>
                        )}
                    </div>
                </div>
                <div className="RightNavContainer">
                    <img
                        src='assets/discord.png'
                        className='SocialIcon'
                        alt="discord"
                        onClick={() => {
                            window.open('https://discord.gg/pXpDa5JVxw', '_blank');
                        }}
                    />
                    <img
                        src='assets/youtube.png'
                        className='SocialIcon'
                        alt="youtube"
                        onClick={() => window.open('https://www.youtube.com/@TheEgman', '_blank')}
                    />
                </div>
            </div>
        </div >
    );
};

export default NavBar;
